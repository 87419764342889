.main {
  width: 100%;
  height: 100vh;
  background: url("../../img/main_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .main {
    background-position: top center;
  }
}
