.header {
  padding: 40px 0 10px 0;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 39px 0 50px;
  }
  &__menu {
    display: flex;
    align-items: center;
    &_link {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      margin-left: 35px;
      text-decoration: none;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
    }
    &_button {
      margin-left: 50px;
      img {
        transition: 0.2s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &__mobile {
    &_menu {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    &__menu {
      &_button {
        img {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding: 18px 0 10px 0;
    position: relative;
    &__menu {
      display: none;
    }
    &__container {
      justify-content: center;
    }
    &__logo {
      width: 181px;
    }
    &__mobile {
      &_menu {
        display: block;
        position: absolute;
        top: 18px;
        right: 10px;
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
      }
    }
  }
}
