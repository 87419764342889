.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background: #651fff;
  max-width: 686px;
  width: 100%;
  height: 426px;
  padding: 65px 65px 39px 65px;
  &__wrapper {
    position: relative;
  }
  &__contacts {
    width: 235px;
    position: absolute;
    top: -107px;
    right: -219px;
  }
  &__donwload {
    width: 191px;
    position: absolute;
    bottom: -493px;
    left: -175px;
  }
}
