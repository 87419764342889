@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

.footer {
  padding: 10px 0 23px 0;
  &__container {
    display: flex;
    /*align-items: flex-start;*/
    justify-content: space-between;
    padding: 0 26px 0 58px;
    align-items: center;
  }
  &__menu {
    display: flex;
    align-items: center;
    column-gap: 8px;

    /*height: 80px;*/
    a {
      img {
        transition: 0.2s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  &__create {
    margin-top: 17px;
    color: #651fff;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    font-family: "Poppins", sans-serif;
  }
  &__right {

    display: flex;
    align-items: center;

    &_button {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 1330px) {
  .footer {
    &__menu {
      a {
        img {
          width: 45px;
        }
      }
    }
    &__right {
      img {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .footer {
    &__menu,
    &__right {
      display: none;
    }
  }
}
