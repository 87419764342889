@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

.circle {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #ff1644;
  transition: 0.2s ease-in-out;
  &__main {
    position: relative;
    width: 100%;
    height: 100%;
    &_mobile {
      &_footer {
        display: none;
      }
    }
    &_control {
      display: none;
    }
    &_footer {
      &_create {
        &_mobile {
          display: none;
        }
      }
      &_copy {
        &_mobile {
          display: none;
        }
      }
    }
  }
  &__text {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    max-width: 423px;
    min-width: 423px;
    height: 130px;
    position: absolute;
    bottom: 448px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__about {
    left: 71px;
    top: 164px;
    &_active {
      svg {
        fill: #ffffff !important;
      }
    }
  }
  &__modes {
    top: 42px;
    left: 158px;
    svg {
      animation-delay: 0.2s;
    }
  }
  &__download {
    left: 426px;
    top: 41px;
    svg {
      animation-delay: 0.3s;
    }
  }
  &__contacts {
    left: 622px;
    top: 131px;
    svg {
      animation-delay: 0.4s;
    }
  }
  &__about,
  &__modes,
  &__download,
  &__contacts {
    cursor: pointer;
    position: absolute;
    svg {
      fill: transparent;
      transition: fill 0.3s ease;
    }
    &:hover {
      svg {
        fill: #ffffff;
      }
    }
  }
  &__emoji {
    transform: translate(50%, calc(-100% + 50px));
    max-height: 45vh;
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: 0.2s ease-in-out;
    &_active {
      visibility: visible;
      opacity: 1;
      display: block;
    }
    &_about {
      transform: translate(50%, calc(-100% + 22px));
    }
    &_modes {
      transform: translate(calc(50% + 80px), calc(-100% + 22px));
    }
    &_download {
      transform: translate(calc(50% + 80px), calc(-100% + 22px));
    }
    &_contacts {
      transform: translate(calc(50% + 80px), calc(-100% + 22px));
    }
  }
  &__block {
    max-width: 423px;
    min-width: 423px;
    height: 130px;
    position: absolute;
    bottom: 473px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
    &_download {
      display: flex;
      margin-top: 33px;
    }
    &_wrapper {
      display: flex;
      align-items: center;
      margin-top: 11px;
      column-gap: 13px;
    }
    &_terms {
      position: absolute;
      bottom: 420px;
      left: 50%;
      transform: translate(-50%);
      text-align: center;
      &_title {
        color: #fff;
        font-weight: 500;
      }
      &_noty {
        margin-top: 8px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .circle {
    width: 600px;
    height: 600px;
    &__about {
      left: 19px;
      top: 144px;
      transform: rotate(-8deg);
      svg {
        width: 70.3px;
      }
    }
    &__modes {
      top: 23px;
      left: 74px;
      transform: rotate(-8deg);
      svg {
        width: 200px;
      }
    }
    &__download {
      left: 306px;
      top: 11px;
      transform: rotate(1deg);
      svg {
        width: 164px;
      }
    }
    &__contacts {
      left: 480px;
      top: 104px;
      transform: rotate(6deg);
      svg {
        width: 94.35px;
      }
    }
    &__text {
      font-size: 16px;
      line-height: 22px;
      max-width: 323px;
      min-width: 323px;
      bottom: 330px;
    }
    &__block {
      bottom: 352px;
      max-width: 323px;
      min-width: 323px;
      &_terms {
        bottom: 320px;
      }
    }
    &__emoji {
      width: 272px;
      &_modes {
        width: 221px;
      }
      &_download {
        width: 231px;
      }
      &_contacts {
        width: 231px;
      }
    }
  }
}

@media screen and (max-width: 1330px) {
  .circle {
    width: 400px;
    height: 400px;
    &__about {
      top: 96px;
      left: 9px;
      transform: rotate(-14deg);
      svg {
        width: 56px;
      }
    }
    &__modes {
      top: 1px;
      left: 46px;
      transform: rotate(-6.5deg);
      svg {
        width: 160px;
      }
    }
    &__download {
      left: 212px;
      top: 6px;
      transform: rotate(8.5deg);
      svg {
        width: 124px;
      }
    }
    &__contacts {
      left: 321px;
      top: 68px;
      transform: rotate(10deg);
      svg {
        width: 71px;
      }
    }
    &__text {
      font-size: 12px;
      line-height: 16px;
      max-width: 223px;
      min-width: 223px;
      bottom: 189px;
    }
    &__block {
      bottom: 177px;
      max-width: 223px;
      min-width: 223px;
      &_title {
        font-size: 16px;
      }
      &_download {
        margin-top: 15px;
        img {
          width: 120px;
        }
      }
      &_wrapper {
        a {
          img {
            width: 24px;
          }
        }
      }
      &_terms {
        bottom: 225px;
      }
      &_contact {
        bottom: 208px;
      }
    }
    &__emoji {
      &_about {
        width: 224px;
        transform: translate(calc(50% - 40px), calc(-100% + 12px));
      }
      &_modes {
        width: 211px;
        transform: translate(50%, calc(-100% + 22px));
      }
      &_download {
        width: 233px;
        transform: translate(calc(50% - 10px), calc(-100% + 22px));
      }
      &_contacts {
        width: 200px;
        transform: translate(calc(50% + 20px), calc(-100% + 8px));
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .circle {
    width: 700px;
    height: 700px;
    &__about,
    &__modes,
    &__download,
    &__contacts {
      display: none;
    }
    &__main {
      &_mobile {
        &_footer {
          display: block;
          position: absolute;
          bottom: 361px;
          width: calc(100% - 24px);
          padding: 0 12px;
        }
      }
      &_menu {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 18px;
        margin-bottom: 18px;
      }
      &_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        &_create {
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          font-family: "Poppins", sans-serif;
          &_mobile {
            display: none;
            font-family: "Poppins", sans-serif;
          }
        }
        &_copy {
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          &_mobile {
            display: none;
            font-family: "Poppins", sans-serif;
          }
        }
      }
      &_control {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        &_current {
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 5;
          line-height: 130%;
          text-align: center;
          width: 211px;
        }
        button {
          border: none;
          outline: none;
          background: none;
          cursor: pointer;
          &:first-child {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &__text {
      bottom: 434px;
      max-width: 423px;
      min-width: 423px;
      font-size: 16px;
      line-height: 130%;
    }
    &__block {
      bottom: 436px;
      max-width: 423px;
      min-width: 423px;
      &_contact {
        .circle__block_title {
          display: none;
        }
        bottom: 434px;
      }
      &_wrapper {
        margin-top: 0;
        a img {
          width: 48px;
        }
      }
      &_terms {
        bottom: 468px;
        &_noty {
          display: none;
          font-family: "Poppins", sans-serif;
        }
      }
    }
    &__emoji {
      &_about {
        transform: translate(100%, calc(-100% + 35px));
      }
      &_modes {
        transform: translate(calc(100% + 10px), calc(-100% + 26px));
      }
      &_download {
        transform: translate(100%, calc(-100% + 30px));
      }
      &_contacts {
        transform: translate(calc(100% + 70px), calc(-100% + 20px));
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .circle {
    &__main {
      &_footer {
        &_create {
          opacity: 0;
          &_mobile {
            display: block;
            position: absolute;
            bottom: 12px;
            left: 12px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
          }
        }
        &_copy {
          opacity: 0;
          &_mobile {
            display: block;
            position: absolute;
            bottom: 12px;
            right: 12px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .circle {
    width: 576px;
    height: 576px;
    bottom: 2%;
    &__text {
      bottom: 345px;
    }
    &__main {
      &_control {
        top: 45px;
      }
      &_mobile {
        &_footer {
          bottom: 290px;
        }
      }
    }
    &__block {
      bottom: 336px;
      &_terms {
        bottom: 391px;
      }
      &_contact {
        bottom: 340px;
      }
    }
    &__emoji {
      &_about {
        transform: translate(166px, calc(-100% + 25px));
      }
      &_modes {
        transform: translate(197px, calc(-100% + 26px));
      }
      &_download {
        transform: translate(176px, calc(-100% + 30px));
      }
      &_contacts {
        transform: translate(215px, calc(-100% + 20px));
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .circle {
    &__text {
      max-width: calc(100vw - 20px);
      min-width: calc(100vw - 20px);
    }
  }
}

@media screen and (max-width: 380px) {
  .circle {
    bottom: 100px;
    &__main {
      &_mobile {
        &_footer {
          bottom: 215px;
        }
      }
    }
    &__text {
      bottom: 325px;
    }
    &__block {
      bottom: 305px;
      &_title {
        font-size: 18px;
      }
      &_terms {
        bottom: 335px;
      }
    }
  }
}
